<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Boas-vindas</h2>
      </div>
    </div>
    <div class="section-content">
      <b-card-group deck >
        <b-card class="card-layout shadow-sm p-3 mb-5 bg-white rounded" style="max-width: 30rem; min-height: 25rem; margin-left: 35px">
          <b-card-title>OLÁ!</b-card-title>
          <b-card-text class="card-subtitle card-text_align">Produtivo, prático e seguro.</b-card-text>
          <b-card-text class="card-text card-text_align">
            O Retextoar é perfeito para otimizar os trabalhos da sua equipe e empresa, uma solução inteligente para transcrição de áudios em texto, totalmente brasileiro e com uma das maiores acuracidades do mercado.
          </b-card-text>
          <b-button class="button-home" href="#" @click="showTutorial()" variant="primary" style="margin-top: 80px;">Assista ao tutorial</b-button>
        </b-card>
          <b-card class="card-layout1 shadow-sm p-3 mb-5 bg-white rounded" style="max-width: 60rem;">
          <b-card-title class="card-title-right">Você sabia?
          </b-card-title>
          <b-card-text class="card-text">
            <ul >
              <li class="card-text1">
                A transcrição de áudios oferece inegável agilidade para empresas e profissionais já que o tempo de uma transcrição manual pode ser 5 vezes maior do que o tempo da duração da própria gravação.
              </li>
              <li class="card-text1">
                Ao utilizar um arquivo de áudio multicanal, o Retextoar consegue identificar os locutores, numerando cada um deles individualmente nas falas transcritas. Para utilizar esta facilidade, selecione a opção “áudio multicanal” na tela de upload do arquivo.
              </li>
              <li class="card-text1">
                O Retextoar trabalha por meio de sistemas baseados em inteligência artificial, dessa forma, sua evolução é constante e faz com que entregue uma das melhores taxas de acuracidade do mercado.
              </li>
              <li class="card-text1">
                Você pode solicitar a transcrição de diversos tipos de arquivos de áudio e vídeo: WAV, MP3, M4A, WMA, FLAC, OGG, MP4 E AVI.
              </li>
            </ul>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
    <b-modal
            id="modal-prevent-closing"
            ref="modal"
            :ok-title="'Ok'"
            :title="modalTitle"
            modal-class="edit-title"
            @hidden="resetModal"
            @ok="handleModalOk"
            size="lg"
            hide-footer
    >
        <b-row>
          <b-col>
            <video width="100%" :src="srcVideo"  controls="true"></video>
          </b-col>
        </b-row>
    </b-modal>
    <div class="section-content">
    </div>
  </section>
</template>

<script>
  export default {
    data () {
      return {
        modalTitle: 'Tutorial',
        srcVideo: 'https://storage.googleapis.com/retextoar-publico/app/videos/tutorial_retextoar_novo_portal.mp4'
      };
    },
    methods: {
      showTutorial () {
        this.$refs.modal.show();
      },
      resetModal () {
      },
      handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    word-wrap: break-word;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #6278A3;
    padding-top: 10px;
  }

  .card-text_align {
    margin-top: 15px;
    font-family: 'Inter';
    font-weight: 500;
  }

  .card-text .card-text, li {
    padding-top: 10px;
    padding-right: 9px;
  }

  .card-layout {
    display: flex;
    flex-direction: row;
    padding: 14px 12px;
    background: #FFFFFF;

    /* cinza bordas */
    border: 1px solid #E6ECF2;
    border-radius: 7px;
  }

  .card-deck {
    margin-bottom: 20px;
  }

  .card-layout1 {
    padding-left: 28px;
    border-radius: 7px;
  }

  .card-title {
    color: #207BDD;
    margin-top: 15px;
    align-items: start;
    font-family: Poppins;
  }

  .card-title-right {
    margin-left: -23px;
    padding-top: 15px;
  }

  .card-subtitle {
    margin-top: 30px;
    font-weight: bold;
  }

  .card-layout .button-home {
    width: 95%;
    margin: 2%;
    line-height: 28px;
  }
</style>
